import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import React from 'react';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/nextjs';
import { getLastPlayerGames } from 'utils/services/api/requests/games';
import { formatGame } from 'utils/functions/games';
import { GameI } from 'utils/functions/games/types';
import GameCarousel from 'components/carousel/gameCarousel';
import { cardsInView } from 'components/lobby/variants/default';
import Link from 'components/customLink';
import SkeletonCard from 'components/cards/skeletonCard';
import Button from 'components/uiKit/buttons';
import GameCardV2 from 'components/cards/GameCardV2';
const DEFAULT_LAST_PLAYED_GAMES_SIZE = 6;
const CACHE_KEY = 'games-last-player';
const COOKIE_CONSENT_KEY = 'cookie-notification-response';
const checkCookieConsent = () => {
  if (typeof window === 'undefined') return false;
  return localStorage.getItem(COOKIE_CONSENT_KEY) === 'true';
};

// Initialize with cached data if available
const getInitialGames = () => {
  if (typeof window === 'undefined') return null;
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    return cached ? JSON.parse(cached) : null;
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        location: 'LastPlayedCarousel',
        action: 'getInitialGames',
        cacheKey: CACHE_KEY
      },
      extra: {
        error: e
      }
    });
    return null;
  }
};
function parsedList(originalGame: any) {
  return formatGame({
    originalGame,
    defaultSize: (game: GameI) => 1
  });
}
export default function LastPlayedCarousel() {
  const {
    appType,
    state,
    dimensions
  } = React.useContext(GlobalContext);
  const {
    appSize
  } = state;
  const {
    data: originalGames,
    isLoading,
    error
  } = useQuery([CACHE_KEY], () => getLastPlayerGames(), {
    staleTime: 0,
    cacheTime: 30 * 24 * 60 * 60 * 1000,
    // 30 days
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    initialData: getInitialGames,
    onSuccess: data => {
      if (!data) return;
      if (!checkCookieConsent()) return;
      try {
        const currentCache = localStorage.getItem(CACHE_KEY);
        // Only update if data is different
        if (currentCache !== JSON.stringify(data)) {
          localStorage.setItem(CACHE_KEY, JSON.stringify(data));
        }
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            location: 'LastPlayedCarousel',
            action: 'cacheUpdate',
            cacheKey: CACHE_KEY
          },
          extra: {
            dataLength: data?.length,
            error: e
          }
        });
      }
    },
    onError: err => {
      Sentry.captureException(err, {
        tags: {
          location: 'LastPlayedCarousel',
          action: 'fetchGames',
          cacheKey: CACHE_KEY
        },
        extra: {
          error: err
        }
      });
    }
  });

  // Show loading state only if we don't have cached data
  if (isLoading && !originalGames) {
    const loadingPlaceholders = Array.from({
      length: DEFAULT_LAST_PLAYED_GAMES_SIZE
    });
    return <Grid gap={appType === 'mobile' ? '0.5rem' : '1rem'} verticalAlgin="center">
        <GameCarousel cards={loadingPlaceholders.map((_, index) => <SkeletonCard key={index} />)} title={<Typography translateGroup="global" translateKey="LAST PLAYED" weight={800} size="md" style={{
        textTransform: 'uppercase'
      }} />} cardsInView={cardsInView[appSize] as any} carouselId="last-played-loading" gap={1} viewAllButton={<Link href="/viimeksi-pelatut">
              <Button id="viimeksi-pelatut" color="outline-primary" size="xsm" styleButton="rounded">
                <Typography translateGroup="game-carousel" translateKey="show-all" style={{
            fontSize: '0.875rem'
          }} />
              </Button>
            </Link>} />
      </Grid>;
  }

  // Handle error state
  // dimensions.width === 0 means we should not render the last played on server side to avoid the error: Unhandled Runtime Error
  if (error || dimensions.width === 0) {
    return <></>; // Silently fail but error is already captured by onError
  }
  const seen = new Set();
  const customGames = (originalGames || [])?.filter((item: any) => {
    const isDuplicate = seen.has(item.id);
    seen.add(item.id);
    return !isDuplicate;
  });
  if (!customGames?.length) {
    return <></>;
  }
  const gamesWithPlaceHolder = customGames.length > DEFAULT_LAST_PLAYED_GAMES_SIZE ? customGames : [...customGames, ...Array(DEFAULT_LAST_PLAYED_GAMES_SIZE - customGames.length).fill(null)];
  return <Grid gap={appType === 'mobile' ? '0.5rem' : '1rem'} verticalAlgin="center" data-sentry-element="Grid" data-sentry-component="LastPlayedCarousel" data-sentry-source-file="index.tsx">
      <GameCarousel cards={gamesWithPlaceHolder.map((game: any, index: number) => {
      if (!game) {
        return <div className="border-2 border-[var(--input-bg)] rounded-[20px] h-full" key={index} />;
      }
      const parsedGame = parsedList(game);
      return <GameCardV2 {...parsedGame} key={`${game.categoryId} - ${game.displayName}`} />;
    })} title={<Typography translateGroup="global" translateKey="LAST PLAYED" weight={800} size="md" className="uppercase" />} cardsInView={cardsInView[appSize] as any} carouselId="last-played" gap={1} viewAllButton={<Link href="/viimeksi-pelatut">
            <Button id="viimeksi-pelatut" color="outline-primary" size="xsm" styleButton="rounded">
              <Typography translateGroup="game-carousel" translateKey="show-all" className="!text-[0.875rem]" />
            </Button>
          </Link>} enableArrows={customGames.length > DEFAULT_LAST_PLAYED_GAMES_SIZE} data-sentry-element="GameCarousel" data-sentry-source-file="index.tsx" />
    </Grid>;
}