import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import GlobalContext from 'context/global';
import Arrow from 'assets/icons/arrow';
import { twMerge } from 'tailwind-merge';
import Draggable from 'gsap/dist/Draggable';
import styles from './styles.module.scss';
gsap.registerPlugin(Draggable);
interface GameCarouselI {
  title: React.ReactNode;
  viewAllButton: React.ReactNode;
  cards: React.ReactNode[];
  cardsInView: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  gap: 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2;
  carouselId: string;
  lastPlayed?: boolean;
  preDefinedCardWidth?: number;
  showNextPrevButtons?: boolean;
  enableDrag?: boolean;
  enableArrows?: boolean;
}
const CARDS_IN_VIEW = 6;
export default function GameCarousel({
  title,
  viewAllButton,
  cards,
  cardsInView = CARDS_IN_VIEW,
  gap = 1,
  carouselId,
  lastPlayed,
  preDefinedCardWidth,
  // preDefinedCardWidth overrides cardsInView
  showNextPrevButtons = true,
  enableDrag = true,
  enableArrows = true
}: GameCarouselI) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const cardWidthRef = useRef(0);
  const {
    appType
  } = React.useContext(GlobalContext);
  const [showLeftFade, setShowLeftFade] = useState(false);
  const [showRightFade, setShowRightFade] = useState(true);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const updateFadeVisibility = () => {
    if (carouselRef.current) {
      const {
        scrollLeft,
        scrollWidth,
        clientWidth
      } = carouselRef.current;
      setShowLeftFade(scrollLeft > 0); // Show left fade when scrolled by at least 1px
      setShowRightFade(scrollLeft < scrollWidth - clientWidth); // Show right fade if not at the end
    }
  };
  useEffect(() => {
    updateFadeVisibility(); // Run once on mount
  }, [cards]);
  useEffect(() => {
    if (carouselRef.current) {
      const cardElement = carouselRef.current.querySelector(`.${styles.card}`);
      if (cardElement) {
        cardWidthRef.current = (cardElement as HTMLElement).offsetWidth + gap * 16;
      }
    }
  }, [gap]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (enableDrag && carouselRef.current && !['mobile', 'tablet'].includes(appType)) {
      const cardElement = carouselRef.current.querySelector(`.${styles.card}`);
      if (cardElement) {
        cardWidthRef.current = (cardElement as HTMLElement).offsetWidth + gap * 16;
      }
      const draggableInstance = Draggable.create(carouselRef.current, {
        type: 'scrollLeft',
        edgeResistance: 0.5,
        inertia: true,
        throwProps: true,
        dragClickables: true,
        allowContextMenu: true,
        snap: {
          scrollLeft: (value: any) => {
            return Math.round(value / cardWidthRef.current) * cardWidthRef.current;
          }
        } as any,
        onDrag: updateFadeVisibility,
        onThrowUpdate: updateFadeVisibility,
        onDragEnd: updateFadeVisibility
      });

      // Cleanup draggable on unmount
      return () => {
        draggableInstance.forEach(instance => instance.kill());
      };
    }
  }, [enableDrag, gap, appType, updateFadeVisibility]);
  const scrollCarousel = (direction: 'left' | 'right') => {
    const carousel = carouselRef.current;
    if (!carousel) return;
    const offset = cardWidthRef.current * CARDS_IN_VIEW;
    const targetScrollLeft = direction === 'right' ? carousel.scrollLeft + offset : carousel.scrollLeft - offset;
    gsap.to(carousel, {
      scrollLeft: targetScrollLeft,
      duration: 0.8,
      ease: 'power2.inOut',
      onUpdate: updateFadeVisibility,
      onComplete: updateFadeVisibility
    });
  };
  const cardWidth = preDefinedCardWidth || `calc((100% - ${gap * (cardsInView - 1)}rem) / ${cardsInView})`;
  const leftRightClassNames = `w-[75px] h-full bg-[rgba(74,38,255,0.75)] absolute top-0 z-50 text-white items-center justify-center cursor-pointer ${styles.leftRightButtons}`;
  return <div className={styles.wrapper} data-sentry-component="GameCarousel" data-sentry-source-file="index.tsx">
      <div className={styles.header}>
        <div className={styles.title} style={{
        width: lastPlayed ? '100%' : '50%'
      }}>
          {title}
        </div>
        {!lastPlayed && <div className={styles.viewAll}>{viewAllButton}</div>}
      </div>

      <div className={styles.content} onFocus={() => setIsMouseOver(true)} onMouseEnter={() => setIsMouseOver(true)} onBlur={() => setIsMouseOver(false)} onMouseLeave={() => setIsMouseOver(false)}>
        {['desktop'].includes(appType) && <>
            <div className={`${styles.leftFade} ${showLeftFade ? styles.showFade : ''}`} />
            <div className={`${styles.rightFade} ${showRightFade ? styles.showFade : ''}`} />
            {enableArrows && <>
                <div className={twMerge(leftRightClassNames, 'left-0 rounded-tl-[12pt] rounded-bl-[12pt]', isMouseOver && showLeftFade && styles.visible)} onClick={() => scrollCarousel('left')}>
                  <Arrow className="-rotate-180" />
                </div>
                <div className={twMerge(leftRightClassNames, 'right-0 rounded-tr-[12pt] rounded-br-[12pt]', isMouseOver && showRightFade && styles.visible)} onClick={() => scrollCarousel('right')}>
                  <Arrow />
                </div>
              </>}

          </>}
        <div className={styles.carouselWrapper} ref={carouselRef} style={{
        overflowX: ['mobile', 'tablet'].includes(appType) ? 'scroll' : 'hidden',
        scrollBehavior: ['mobile', 'tablet'].includes(appType) ? 'smooth' : 'unset'
      }}>
          <div className={styles.carouselContent}>
            {cards.map((card, index) => <div key={`${carouselId}-card-${index}`} className={styles.card} style={{
            width: cardWidth
          }}>
                {card}
              </div>)}
          </div>
        </div>
      </div>
    </div>;
}