import { useState, useEffect, useContext } from 'react';
import { parseLobbyGames } from 'utils/functions/games';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { getLobby } from 'utils/services/api/requests/games';
import GlobalContext from 'context/global';
import { LobbyFilterItemsI } from './categoryFilter/filter';
interface Props {
  lobbyName: string;
}
export function useLobby({
  lobbyName
}: Props) {
  const {
    appType
  } = useContext(GlobalContext);
  const isMobile = ['mobile', 'table'].includes(appType);
  const {
    data: originalGames,
    isLoading
  } = useQuery(`games-lobbies-${lobbyName}`, () => getLobby(lobbyName, isMobile), {
    staleTime: 120000,
    refetchOnWindowFocus: false,
    cacheTime: 1000000
  });
  const parsedLobby = parseLobbyGames({
    originalGames
  }).filter(({
    category
  }: any) => {
    if (category?.enabled) {
      return category;
    }
    return null;
  });
  const categories = parsedLobby.map(c => ({
    ...c.category
  }));
  const [currentCategory, setCurrentCategory] = useState(categories[0]?.name);
  const [searchLobby, setSearchLobby] = useState('');
  const router = useRouter();
  useEffect(() => {
    const url = new URL(window.location.toString());
    if (url.searchParams.has('category')) {
      const searchParam = url.searchParams.get('category') ?? categories[0]?.name;
      setCurrentCategory(searchParam);
      setSearchLobby(searchParam);
    } else {
      setCurrentCategory(categories[0]?.name);
      setSearchLobby('');
    }
  }, [categories, router]);
  function setCurrentSearch(search: string) {
    const url = new URL(window.location.toString());
    url.searchParams.set('category', String(search));
    window.history.pushState({}, '', url);
    setSearchLobby(search);
    setCurrentCategory(search);
  }
  const categoryFilterItems: Array<LobbyFilterItemsI> = categories.map(category => ({
    icon: category.icon,
    label: category.name,
    iconPosition: category.iconPosition,
    onClick: () => setCurrentSearch(category.name)
  }));
  const currentCategoryInfo = categories.find(c => c.name === currentCategory);
  return {
    searchLobby,
    setCurrentSearch,
    categoryFilterItems,
    currentCategory,
    currentCategoryInfo,
    categories,
    isLoading
  };
}