/* eslint-disable react/prop-types */
import React from 'react';
export default function Arrow({
  width = 18,
  height = 30,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="Arrow" data-sentry-source-file="arrow.tsx">
            <path d="M1.48482 29.1421L15.627 15L1.48482 0.857864" stroke="currentColor" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="arrow.tsx" />
        </svg>;
}