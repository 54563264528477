import Link from 'next/link';
import Image from 'next/image';
import { Supplier } from 'utils/services/api/requests/games/types';
import styles from './styles.module.scss';
type SupplierCardProps = {
  supplier: Supplier;
  large?: boolean;
};
export default function SupplierCard({
  supplier,
  large
}: SupplierCardProps) {
  const logoPath = `/assets/game-providers/${supplier.supplier_logo}`;
  return <Link href={`/pelivalmistajat/${supplier.url}`} passHref key={supplier.url} className="w-full" data-sentry-element="Link" data-sentry-component="SupplierCard" data-sentry-source-file="index.tsx">
      <div className={`${styles.card} ${large ? styles.cardBig : ''}`}>
        <Image src={logoPath} alt={supplier.name} className={styles.image} quality={100} fill data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </div>
    </Link>;
}