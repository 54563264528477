import Grid from 'components/uiKit/grid';
import GlobalContext from 'context/global';
import React from 'react';
import GameCarousel from 'components/carousel/gameCarousel';
import SupplierCard from 'components/supplierCard';
import { getSuppliers } from 'utils/services/api/requests/games';
import { useQuery } from 'react-query';
import { Supplier } from 'utils/services/api/requests/games/types';
import SkeletonSupplierCard from 'components/supplierCard/skeletonSupplierCard';
import Typography from 'components/uiKit/typography';
import Link from 'next/link';
import Button from 'components/uiKit/buttons';
import styles from '../carousel/gameCarousel/styles.module.scss';
export default function SupplierCarousel() {
  const {
    appType
  } = React.useContext(GlobalContext);
  const {
    data: gameSuppliers = [],
    isLoading,
    error
  } = useQuery<Supplier[]>(['game-suppliers'], () => getSuppliers());
  const loadingPlaceholders = Array.from({
    length: 9
  });
  return <Grid className={styles.wrapper} gap={appType === 'mobile' ? '0.5rem' : '1rem'} verticalAlgin="center" data-sentry-element="Grid" data-sentry-component="SupplierCarousel" data-sentry-source-file="index.tsx">
      <div className={styles.header}>
        <div className={styles.title}>
          <Typography translateGroup="game-carousel" translateKey="pelivalmistajat" weight={800} size="md" style={{
          textTransform: 'uppercase'
        }} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />

        </div>
        <div className={styles.viewAll}>
          <Link href="/pelivalmistajat" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <Button id="view-all-pelivalmistajat" color="outline-primary" size="xsm" styleButton="rounded" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              <Typography translateGroup="game-carousel" translateKey="show-all" style={{
              fontSize: '0.875rem'
            }} data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
            </Button>
          </Link>
        </div>
      </div>
      <GameCarousel cards={isLoading ? loadingPlaceholders.map((_, index) => <SkeletonSupplierCard key={index} /> // Render skeleton cards when loading
    ) : gameSuppliers.map((supplier: Supplier, index: number) => <SupplierCard supplier={supplier} key={index} />)} title="" cardsInView={6} // preDefinedCardWidth overrides cardsInView
    carouselId="SupplierCarousel" gap={1} preDefinedCardWidth={130}
    // If you want to add a view all button, just add it here
    viewAllButton={<></>} showNextPrevButtons={false} enableArrows={false} data-sentry-element="GameCarousel" data-sentry-source-file="index.tsx" />
    </Grid>;
}