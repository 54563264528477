import Button from 'components/uiKit/buttons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { GameParsedI } from 'utils/functions/games/types';
import styles from './styles.module.scss';
export default function GameCardV2({
  link,
  thumbnail,
  name,
  linkLabel
}: GameParsedI) {
  const {
    push
  } = useRouter();
  const gameSlug = link.split('/')?.pop() ?? 'gameSlug';
  return <Link href={link} style={{
    width: '100%'
  }} data-sentry-element="Link" data-sentry-component="GameCardV2" data-sentry-source-file="index.tsx">
      <div onClick={() => push(link)} className={styles['game-card-default']} style={{
      background: `url(${thumbnail})`
    }}>
        <img src={thumbnail} alt={name} />
        <Button id={gameSlug} color="yellow" onClick={() => push(link)} size="md" data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {linkLabel}
        </Button>
      </div>
    </Link>;
}