/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import GameCarousel from 'components/carousel/gameCarousel';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import React, { useContext } from 'react';
import SupplierCarousel from 'components/supplierCarousel';
import Button from 'components/uiKit/buttons';
import { cardsInView } from '../variants/default';
import SkeletonCard from '../../cards/skeletonCard';
export default function LoadingLobby() {
  const {
    state
  } = useContext(GlobalContext);
  const {
    appSize
  } = state;
  // Generate 7 dummy categories with 6 skeleton cards each
  const loadingCategories = Array.from({
    length: 7
  }, (_, i) => ({
    name: `Category ${i + 1}`,
    games: Array.from({
      length: 6
    }, () => ({})) // Empty objects as placeholders
  }));
  return <Grid gap="2rem" padding={['pt-5', 'pb-5']} data-sentry-element="Grid" data-sentry-component="LoadingLobby" data-sentry-source-file="index.tsx">
            <SupplierCarousel data-sentry-element="SupplierCarousel" data-sentry-source-file="index.tsx" />
            {loadingCategories.map((category, index) => <GameCarousel gap={1} title={<Typography weight={800} size="md" style={{
      textTransform: 'uppercase',
      filter: 'blur(4px)',
      // Apply blur effect to text
      color: 'rgba(74, 38, 255, 0.3)'
    }}>
                            {category.name}
                        </Typography>} viewAllButton={
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Button id="view-all" color="outline-primary" size="xsm" styleButton="rounded">
                            <Typography style={{
        fontSize: '0.875rem'
      }}>Näytä kaikki</Typography>
                        </Button>} cards={category.games.map((_, gameIndex) => <SkeletonCard key={gameIndex} /> // Placeholder card
    )} key={`loadingLobby-${category.name}-${index}`} cardsInView={cardsInView[appSize] as any || 2} // Adjust as needed
    carouselId={`loading-${category.name}`} enableDrag={false} />)}
        </Grid>;
}